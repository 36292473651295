@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}
@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: none;
    margin: 1px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #f6b098;
    border-radius: 100vh;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #f18966;
  }
}

.semi-circle {
  --height: 15px;

  height: calc(2 * var(--height));
  width: calc(4 * var(--height));
  border-radius: calc(5 * var(--height)) calc(5 * var(--height)) 0 0;
  background: #f7f6f3;
  transform: rotate(180deg);
}

img {
  -webkit-user-drag: none;
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8fafc;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.page {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.page {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@font-face {
  font-family: 'ArbFONTS-Regular';
  src: url('../public/assets/fonts/ArbFONTS-MyriadArabic-Bold.otf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ArabFont-normal';
  src: url('../public/assets/fonts/ArbFONTS-MyriadArabic-Regular.otf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ArbFONTS-Bold';
  src: url('../public/assets/fonts/ArbFONTS-MyriadArabic-Bold.otf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Typewriter-Regular';
  src: url('../public/assets/fonts/Typewriter-Serial-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mada-Regular';
  src: url('../public/assets/fonts/Mada-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'JF-Flat-regular';
  src: url('../public/assets/fonts/JF-Flat-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis-Regular';
  src: url('../public/assets/fonts/Metropolis-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  /* background-image: url('assets/images/calendar.svg'); */
  width: 24px;
  height: 24px;
}

.custom-calendar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  position: relative;
}

.custom-calendar .react-datepicker__header {
  background-color: #ffffff;
  border-bottom: hidden;
  justify-content: space-between;
  align-items: center;
  color: rgb(0, 0, 0);
  padding: 0 8px;
}

.custom-calendar .react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.custom-calendar .react-datepicker__current-month {
  margin: 0;
  color: rgb(0, 0, 0);
  flex-grow: 1;
  text-align: left;
  margin-top: 2%;
}

.custom-calendar .react-datepicker__navigation--previous {
  color: #fff;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 75%;
  margin-top: 1.5%;
}

.custom-calendar .react-datepicker__navigation-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.custom-calendar .react-datepicker__day {
  color: #333;
}

.custom-calendar .react-datepicker__day--outside-month {
  visibility: hidden;
}

.custom-calendar .react-datepicker__day--selected,
.custom-calendar .react-datepicker__day--keyboard-selected {
  background-color: #fff;

  color: rgb(0, 0, 0);
}

.custom-calendar .react-datepicker__day--today {
  font-weight: bold;
  border: 1px solid orange;
  border-radius: 50%;
}

.custom-datepicker {
  width: 100%;
}

.custom-datepicker {
  width: 100%;
}

.orange-border-day,
.orange-background-day {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orange-border-day {
  border: 1.5px solid orange !important;
  border-radius: 100% !important;
}

.orange-background-day {
  background-color: orange !important;
  color: #fff !important;
  border-radius: 100% !important;
}

.custom-calendar .react-datepicker__day {
  overflow: hidden;
}

.custom-calendar .react-datepicker__day:hover {
  background-color: #f0f0f0;
  color: #333;
  border-radius: 100%;
}
.react-datepicker__triangle {
  display: none !important;
}

.custom-calendar .react-datepicker__day--disabled {
  color: #ccc;
  pointer-events: none;
  /* background-color: #f9f9f9;  */
}

.react-datepicker__input-container input:focus,
.react-datepicker__input-container input {
  outline: none;
  box-shadow: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #f18966 !important;
  color: inherit;
  border-radius: 50%;
  color: #fff !important;
}

.custom-calendar .react-datepicker__day--selected {
  background: #f18966;
  border-radius: 50%;
}

.scrollable-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.chart-container {
  min-width: 800px;
  height: 320px;
}
